import React from 'react'
import Layout from '../components/layout'
import { graphql, Link } from 'gatsby'
import Divider from '../components/divider'
import './about.css'


const About = ({ data }) => {
  const {
    body,
    members
  } = data.contentfulAbout

  const convertedMembers = members.map((member, i) => {
    return (
      <a href={member.mainsns} target="_blank" rel="noopener noreferrer" key={'member-'+i}>
        <div className='member'>{member.name}</div>
      </a>
    )
  })

  // var t0 = performance.now();
  let countOfflineEvents = 0
  let countConferences = 0
  let countPodcasts = 0
  let countVideos = 0
  let prvNum = 0
  data.allContentfulBlogPost.edges.map((edge, i) => {
    const activityTypeToCompare = edge.node.activityType
    if(activityTypeToCompare.includes('Day')) {
      countOfflineEvents++
    } else if(activityTypeToCompare.includes('Con')) {
      if(edge.node.numbering[0] !== prvNum) {
        countConferences++
      }
      prvNum = edge.node.numbering[0]
      // console.log(edge.node.numbering[0])
    } else if(activityTypeToCompare.includes('Table')) {
      countPodcasts++
    } else if(activityTypeToCompare.includes('Box')) {
      countVideos++
    }
  })


  // let offlineEvents = []
  // let conferences = []
  // let podcasts = []
  // let videos = []
  // data.allContentfulBlogPost.edges.map(edge => {
  //   const activityTypeToCompare = edge.node.activityType
  //   if(activityTypeToCompare.includes('Day')) {
  //     offlineEvents.push(edge.node.numbering[0])
  //   } else if(activityTypeToCompare.includes('Con')) {
  //     conferences.push(edge.node.numbering[0])
  //   } else if(activityTypeToCompare.includes('Table')) {
  //     podcasts.push(edge.node.numbering[0])
  //   } else if(activityTypeToCompare.includes('Box')) {
  //     videos.push(edge.node.numbering[0])
  //   }
  // })
  // console.log(offlineEvents)
  // let newTest = [...new Set(offlineEvents)]
  // conferences = [new Set(conferences)]
  // podcasts = [new Set(podcasts)]
  // videos = [new Set(videos)]



  return (
    <Layout>
      <div className="about-container">

        <div className='about-title'>Design Spectrum</div>

        <div className='about-body'>
          <div dangerouslySetInnerHTML={{__html: body.childMarkdownRemark.html}}></div>
        </div>

        <div className='about-bottom-bg'></div>

        <div className='activity-log-container'>
          <div className='title-flex'>
            <div className='title-line'></div>
            <div className='title-width-line'>활동 내용</div>
            <div className="title-buffer"></div>
          </div>

          <div className='activity-logs'>
            <div className='activity-count-container'>
              <Link to='/spectrumday/'>
                <div className='activity-count'>{countOfflineEvents}</div>
                오프라인 행사
              </Link>
            </div>
            <div className='activity-count-container'>
              <Link to='/spectrumcon/'>
                <div className='activity-count'>{countConferences}</div>
                대규모 컨퍼런스
              </Link>
            </div>
            <div className='activity-count-container'>
              <Link to='/designtable/'>
                <div className='activity-count'>{countPodcasts}</div>
                팟캐스트
              </Link>
            </div>
            <div className='activity-count-container'>
              <Link to='/designbox/'>
                <div className='activity-count'>{countVideos}</div>
                영상 컨텐츠
              </Link>
            </div>
          </div>
        </div>

        <div className='members-container'>
          <div className='members-title'>
            <div className='title-flex'>
              <div className='title-line'></div>
              <div className='title-width-line'>만드는 사람들</div>
              <div className="title-buffer"></div>
            </div>
            <div className='members'>
              {convertedMembers}
            </div>
          </div>
        </div>

        <Divider shouldFullWidth={true} />

        <div className='about-contact-container'>
          <div className='title-flex'>
            <div className='title-line'></div>
            <div className='title-width-line'>Contact</div>
          </div>
          <div className='email-address'>
            <a href="mailto:contact@designspectrum.org">contact@designspectrum.org</a>
          </div>
          <div> </div>
        </div>
      </div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  {
    contentfulAbout {
      body {
        childMarkdownRemark {
          html
        }
      }
      members {
        name
        mainsns
      }
    },
    allContentfulBlogPost {
      edges {
        node {
          activityType
          numbering
        }
      }
    }
  }
`
