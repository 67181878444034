import React from 'react'
import './divider.css'


const Divider = ({ shouldFullWidth }) => {
  return (
    <div className={shouldFullWidth ? 'divider-container full' : 'divider-container'}>
      <div className='divider'></div>
    </div>
  );
}

export default Divider
